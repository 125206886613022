.navBar {
  position: fixed;
  width: 100%;
  height: var(--navbarHeight);
  padding: var(--u2);
  box-sizing: border-box;
  box-shadow: var(--shadow);
  background: var(--background);
  z-index: var(--layer-3);
  top: 0;
  display: none;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--u);
    width: 100%;
    margin: 0 auto;

    .logo {
      cursor: pointer;
      width: 30%;
      height: 100%;
      position: relative;
    }

    .buttonContainer {
      width: 150px;
      height: var(--u6);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.icon {
  cursor: pointer;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--u3);
  list-style: none;
  font-weight: 600;
  background: var(--background);
  margin: 0;
  padding: 0;
  text-align: center;
  padding: var(--u3) 0px;
  position: fixed;
  z-index: var(--layer-7);
  top: var(--navbarHeight);
  display: none;

  .link {
    color: var(--gray-500);
    cursor: pointer;
    padding: var(--smallUnit);
  }

  .disconnect {
    @extend .link;
    border-radius: 100px;
    width: 50%;
    margin: 0 auto;
    margin-top: var(--u2);
    background: var(--gray-50);
    color: var(--gray-900);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--smallUnit);
  }

  .theme {
    @extend .disconnect;
    background: none;
  }

  .active {
    @extend .link;
    font-weight: bold;
    color: var(--gray-900);
  }
}

//Only for Testnet Demo
.discordFeedbackLink {
  display: flex;
  justify-content: center;
  color: #008cf4;
  font-size: var(--font-12);
  text-decoration: underline;
  text-underline-offset: var(--smallUnit);
}
.discordFeedbackLink:hover {
  color: #34c53a;
}

@media (max-width: 769px) {
  .navBar {
    display: block;
  }

  .menu {
    display: block;
  }
}
