.overlay {
  width: 100%;
  height: 100%;
  background: var(--overlay-background);
  opacity: var(--defaultOpacity);
  z-index: var(--layer-5);
  position: fixed;
  box-sizing: border-box;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
