.modal {
  position: fixed;
  width: 600px;
  height: fit-content;
  left: calc(50% - 300px);
  top: 5%;
  background: var(--background);
  box-shadow: var(--shadow-tables);
  z-index: var(--layer-6);
  display: flex;
  padding: var(--u4) var(--u4);
  padding-left: var(--u2);

  .closeButton {
    position: absolute;
    top: var(--u2);
    right: var(--u2);
    cursor: pointer;
  }
}

@media (max-width: 769px) {
  .modal {
    width: 100%;
    left: auto;
  }
}
